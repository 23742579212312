<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>My Holidays</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">My Holidays</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <b-row>
                <b-col lg="3" sm="12" class="mb-1rem">
                    <div class="form-group row">
                        <label
                            class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap"
                        >Year</label>
                        <div class="col-lg-9 col-md-10 col-sm-12">
                            <b-form-select
                                v-model="year"
                                :options="years"
                                disabled-field="notEnabled"
                            ></b-form-select>
                        </div>
                    </div>
                </b-col>

                <b-col lg="2" class="mb-1rem">
                    <button class="btn btn-primary" @click="onSelection()">
                        Filter
                    </button>
                </b-col>
            </b-row>

            <b-table
                show-empty
                striped
                hover
                :items="items"
                :fields="fields"
                responsive
            >
            </b-table>

        </b-container>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            year: new Date().getFullYear(),
            api_error: "",
            fields: [
                { key: "date"},
                { key: "name"}
            ],
            items: null,
            filter: null,
            access: false,
            years: [],
            total_years:2,
            alertDismissCountDown: 0,
            alertDismissSecs: 10
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_VIEW_MY_HOLIDAYS");
        this.getYears();
        this.onPageLoad();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        getYears() {
            const currentYear = (new Date()).getFullYear();
            for (let index = 0; index < this.total_years; index++) {
                this.years.push({
                    value: currentYear-index, text:currentYear-index
                })
            }
        },

        getDefaultFilters() {
            let api_params = {
                year: this.year,
                order:"asc",
                sort_by: "date",
            };
            return api_params;
        },

        onPageLoad() {
            const api_params =
                Object.keys(this.$route.query).length > 0
                    ? this.$route.query
                    : this.getDefaultFilters();
            this.year = api_params.year;
            this.getMyHolidays(api_params);
        },

        getMyHolidays(params) {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = params;
            this.axios.get(this.$api.get_my_holidays, query).then(response => {
                this.items = response.data.data[0];
                this.updateProgressBar(true);
                this.$router.push({
                    path: this.$route.params[0],
                    query: this.deleteEmptyKeys(params)
                }).catch(()=>{})
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.items = [];
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        onSelection() {
            this.getMyHolidays(this.getDefaultFilters());
        },
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
</style>
